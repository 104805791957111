<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-usuario-export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar listado Usuario</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="nombre" class="col-md-5">Nombre</label>
                    <input
                      type="text"
                      id="nombre"
                      v-model="filtros.nombre"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="email" class="col-md-5">Email</label>
                    <input
                      type="text"
                      id="email"
                      v-model="filtros.email"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="tipo_documento" class="col-md-5"
                      >Tipo de Documento</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.type_document"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_documento in listasForms.tipos_documento"
                        :key="tipo_documento.numeracion"
                        :value="tipo_documento.numeracion"
                      >
                        {{ tipo_documento.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="n_documento" class="col-md-5"
                      >Numero de documento</label
                    >
                    <input
                      type="number"
                      id="n_documento"
                      v-model="filtros.n_documento"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="cel" class="col-md-5">Celular</label>
                    <input
                      type="number"
                      id="cel"
                      v-model="filtros.cel"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="empre" class="col-md-5">Empresa</label>
                    <v-select
                      class="form-control col-md-7 p-0"
                      v-model="empresa"
                      placeholder="empresa"
                      label="razon_social"
                      :options="listasForms.empresas"
                      @input="selectEmpresa()"
                    >
                    </v-select>
                  </div>
                  <div class="form-group row">
                    <label for="estado1" class="col-md-5">Estado</label>
                    <select
                      id="estado1"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.status"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre1"
                              v-model="form.nombre"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre1"
                              >Nombre</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="email"
                              v-model="form.email"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="email"
                              >Email</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_documento"
                              v-model="form.type_document"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_documento"
                              >Tipo de Documento</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="n_documento"
                              v-model="form.n_documento"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="n_documento"
                              >Numero de Documento</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="cel"
                              v-model="form.cel"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="cel"
                              >Celular</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="empresa"
                              v-model="form.empresa"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="empresa"
                              >Empresa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado2"
                              v-model="form.status"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado2">
                              Estado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="rol"
                              v-model="form.rol"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="rol"
                              >Rol</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('admin.usuarios.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
export default {
  name: "UsuarioExport", //llegada tambien
  components: { Loading, vSelect },
  data() {
    return {
      cargando: false,
      empresa: {},
      form: {
        nombre: true,
        email: true,
        type_document: true,
        n_documento: true,
        cel: true,
        rol: true,
        status: true,
        empresa: true,
      },
      filtros: {
        nombre: null,
        email: null,
        type_document: null,
        n_documento: null,
        cel: null,
        status: null,
        empresa_id: null,
      },
      listasForms: {
        tipos_documento: [],
        estados: [],
        empresas: [],
      },
    };
  },

  methods: {
    async getTiposIdentificacion() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipos_documento = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    selectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/usuarios/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getTiposIdentificacion();
    await this.getEmpresas();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
